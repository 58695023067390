import styled, { css } from 'styled-components';
import { ShoppingBag, Tag, DollarSign } from 'styled-icons/feather';
import { Flex } from 'reflexbox/styled-components';
import { lighten, saturate } from 'polished';

import { media } from '@/themes';
import Section from '@/components/section';
import Title from '@/components/title';
import SubTitleBase from '@/components/sub-title';
import Paragraph from '@/components/paragraph';
import Card from '@/components/card';
import NotFullColumn from '@/components/not-full-column';

const Content = styled.div`
  text-align: center;
`;

const SubTitle = styled(SubTitleBase)`
  color: ${props => props.theme.colors.typography.subtle};
  margin-bottom: 3.5em;
`;

const Benefit = styled(Card)`
  align-items: center;
  display: flex;
  width: 100%;

  h4 {
    font-size: 1.5em;
    font-weight: 400;
    margin: 0 0 .25em;

    ${media.sm`
      font-size: 1.25em;
    `};
  }

  p {
    font-size: 1.25em;
    font-weight: 200;
    margin: 0;

    ${media.sm`
      font-size: 1em;
    `};
  }

  & + & {
    margin-top: 2em;

    ${media.sm`
      margin-top: 1em;
    `};
  }
`;

const Circle = styled.div`
  align-items: center;
  border-radius: 50%;
  box-shadow: ${props => props.theme.shadows.hover};
  display: flex;
  flex-shrink: 0;
  height: 7em;
  justify-content: center;
  margin: 0 4em;
  width: 7em;

  ${media.sm`
    height: 5em;
    margin: 0 1em 0 0;
    width: 5em;
  `};

  ${({ color }) => css`
    background-color: ${lighten(.25, saturate(.25, color))};
    color: ${color};
  `};

  svg {
    height: 3em;
    stroke-width: 1.75;
    width: 3em;
  }
`;

const BetterMoney = () => {
  return (
    <Section style={{ paddingTop: 0 }}>
      <Content>
        <Flex>
          <NotFullColumn>
            <Title>Send. Receive. Earn.</Title>
            <SubTitle maxSize="2em" minSize="1.5em" vw={5}>We&apos;ve completely overhauled the payment experience by making it simple, free, and rewarding. 🎉</SubTitle>
            <Benefit>
              <Circle color="#d45f8a"><ShoppingBag/></Circle>
              <div>
                <h4>Get paid to shop.</h4>
                <Paragraph>Shop at brands you already know and love and get instant cash back.</Paragraph>
              </div>
            </Benefit>

            <Benefit>
              <Circle color="#848dd4"><DollarSign/></Circle>
              <div>
                <h4>Send and receive money for free.</h4>
                <Paragraph>Buy gift cards for others or instantly send them Bidali Cash <sup>2</sup> and let them choose.</Paragraph>
              </div>
            </Benefit>

            <Benefit>
              <Circle color="#9382ca"><Tag /></Circle>
              <div>
                <h4>Get exclusive offers.</h4>
                <Paragraph>Stack your rewards by combining cash back with exclusive offers from amazing brands.</Paragraph>
              </div>
            </Benefit>
          </NotFullColumn>
        </Flex>
      </Content>
    </Section>
  );
};

export default BetterMoney;
