import { useState, Children } from 'react';
import styled, { css } from 'styled-components';

import { colors, media } from '@/themes';

const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 2em;
`;

const Tab = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  border-bottom: .125em solid transparent;
  color: ${props => props.textColor};
  cursor: pointer;
  display: flex;
  font-size: 2em;
  font-size: min(max(1.125em, 5vw), 2em);
  font-weight: 400;
  padding: .25em 0;
  text-transform: capitalize;

  ${({ activeColor, isSelected }) => isSelected && css`
    border-color: ${activeColor};
    color: ${activeColor};
  `};

  ${media.sm`
    font-size: 1.35em;
  `};

  ${media.xs`
    font-size: 1.125em;
  `};

  & + & {
    margin-left: 1em;
  }
`;

const TabContent = styled.div``;

const Tabs = ({ children, textColor, activeColor }) => {
  const [selected, setSelected] = useState(0);
  const handleClick = key => () => {
    setSelected(key);
  };

  return (
    <>
      <TabsContainer>
        {Children.map(children, (child, index) => {
          if (!child.props.tabTitle) {
            console.warn(`One or more tabs are missing a 'tabTitle' prop. ${child._source.fileName}:${child._source.lineNumber}`);
          }
          return !!child.props.tabTitle && (
            <Tab
              activeColor={activeColor}
              isSelected={selected === index}
              key={index}
              onClick={handleClick(index)}
              textColor={textColor}>
              {child.props.tabTitle}
            </Tab>
          );
        })}
      </TabsContainer>
      <TabContent>
        {Children.map(children, (child, index) => {
          return selected === index && child;
        })}
      </TabContent>
    </>
  );
};

Tabs.defaultProps = {
  activeColor: colors.brand.primary,
  textColor: colors.typography.primary
};

export default Tabs;
