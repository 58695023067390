import styled from 'styled-components';
import { Flex, Box } from 'reflexbox/styled-components';
import Image from 'next/image';

import { media } from '@/themes';
import Section from '@/components/section';
import Title from '@/components/title';
import SubTitleBase from '@/components/sub-title';
import Paragraph from '@/components/paragraph';
import Tabs from '@/components/tabs';
import Button from '@/components/button';

const Subtitle = styled(SubTitleBase)`
  color: ${props => props.theme.colors.typography.subtle};
`;

const TabContent = styled(Paragraph)`
  strong {
    font-weight: 600;
  }
`;

const Images = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;

  ${media.sm`
    align-items: center;
  `};

  ${media.md`
    align-items: center;
  `};

  > div {
    border-radius: 1em;
    box-shadow: ${props => props.theme.shadows.hover};

    ${media.xs`
      max-width: calc(100vw - 2em) !important;
    `};

    &:last-child {
      position: absolute !important;
      right: 10%;
      top: 0;

      ${media.sm`
        top: -3em;
        right: 0;
        transform: scale(.85);
        transform-origin: right top;
      `};
    }
  }

  img {
    border-radius: 1em;
  }
`;

const CallToAction = styled.div`
  align-items: center;
  display: flex;

  ${media.sm`
    flex-direction: column;
    text-align: center;
  `};

  h3 {
    font-size: 1.75em;
    font-weight: 400;
    margin-right: .75em;

    ${media.sm`
      margin-right: 0;
    `};
  }

  button {
    white-space: nowrap;
  }
`;

const SalesFlex = styled.h2`
  color: ${props => props.theme.colors.typography.verySubtle};
  font-size: 3em;
  text-align: center;

  ${media.sm`
    font-size: 2em;
  `};
`;

const Business = () => {
  return (
    <Section style={{ paddingTop: 0 }}>
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1, 1/2]} pr={[0, 0, 0, '1em']}>
          <Images>
            <Image width={550} height={460} src="/images/people/salon.jpg" alt="Hair Salon" />
            <Image
              alt="In Store payment"
              width={247}
              height={165}
              src="/images/people/paying-at-store.jpg" />
          </Images>
        </Box>
        <Box width={[1, 1, 1, 1/2]} pl={[0, 0, 0, '1em']}>
          <Title>Better for Business.</Title>
          <Subtitle>Building a business is hard. We&apos;re here to help.</Subtitle>
          <Tabs>
            <TabContent tabTitle="Save">
              <strong>Eliminate fees and get paid faster</strong>. Payments with Bidali take seconds to settle and have no processing fees. Together we&apos;ll help you put more money back into your business.
            </TabContent>
            <TabContent tabTitle="Grow">
              <strong>Be local, go global</strong>. Get your brand in front of millions of customers down the street and around the world. With our unique incentive and gift card programs you can reach customers anywhere you can deliver your products and services.
            </TabContent>
            <TabContent tabTitle="Relax">
              <strong>Rest easy and focus on your business</strong>. With Bidali, not only do you get paid faster, but fraud and charge-backs are a thing of the past. Take back control with our innovative gift card and payment solutions.
            </TabContent>
          </Tabs>
          <CallToAction>
            <h3>Want to use Bidali for your business?</h3>
            <Button primary gaLabel="business_signup_cta" to="https://webforms.pipedrive.com/f/2XDkDOjqONUu7rx7Qk5I54WHpBDOy5cvlydcajemhzrBLh1GNpu5rvXC2IQr05pvl">Contact Sales</Button>
          </CallToAction>
        </Box>
      </Flex>
      <SalesFlex>We’ve driven millions in sales for hundreds of brands.</SalesFlex>
    </Section>
  );
};

export default Business;
