import styled, { css } from 'styled-components';
import { Flex, Box } from 'reflexbox/styled-components';
import { Center } from '@bedrock-layout/center';

import { media } from '@/themes';
import Section from '@/components/section';
import TitleBase from '@/components/title';
import Paragraph from '@/components/paragraph';
import AppStoreBadges from '@/components/app-store-badges';

const Title = styled(TitleBase)`
  color: ${props => props.theme.colors.brand.primary};
  margin: 0 0 .25em;

  ${media.sm`
    text-align: center;
  `};

  ${({ layout }) => layout === 'vertical' && css`
    text-align: center;
  `};
`;

const Description = styled(Paragraph)`
  margin: 0;

  ${media.sm`
    text-align: center;
  `};

  ${({ layout }) => layout === 'vertical' && css`
    text-align: center;
  `};
`;

const AppDownloadCTA = ({ description, layout, title }) => {
  const width = layout === 'vertical' ? [1] : [1, 1/2];

  return (
    <Section>
      <Flex flexWrap="wrap">
        <Box width={width}>
          <Title maxSize="3em" minSize="2em" layout={layout}>{title || 'Ready to start saving?'}</Title>
          {description !== false &&
            <Description maxSize="2em" layout={layout}>{description || 'Download the app today!'}</Description>
          }
        </Box>
        <Box width={width}>
          <Center centerChildren>
            <AppStoreBadges />
          </Center>
        </Box>
      </Flex>
    </Section>
  );
};

export default AppDownloadCTA;
