import styled from 'styled-components';
import { ArrowRight } from 'styled-icons/feather';
import { Flex, Box } from 'reflexbox/styled-components';;
import Image from 'next/image';

import { colors } from '@/themes';
import Section from '@/components/section';
import TitleBase from '@/components/title';
import Paragraph from '@/components/paragraph';
import Tabs from '@/components/tabs';
import Button from '@/components/button';
import Phone from '@/components/phone';

const Title = styled(TitleBase)`
  color: ${props => props.theme.colors.brand.accent};
`;

const Description = styled(Paragraph)`
  color: ${props => props.theme.colors.typography.inverted};
`;

const TabContent = styled(Paragraph)`
  color: ${props => props.theme.colors.typography.invertedSubtle};

  button {
    color: ${props => props.theme.colors.brand.accent};
    margin-left: 1em;

    &:hover {
      color: ${props => props.theme.colors.typography.inverted};
    }
  }
`;

const PhoneContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const FaceId = styled.div`
  align-items: center;
  background: white;
  border-radius: 1em;
  box-shadow: ${props => props.theme.shadows.primary};
  display: flex;
  padding: .5em;
  position: absolute;
  top: -2em;
  z-index: 2;
`;

const Security = () => {
  return (
    <Section angleBottomReverse backgroundColor="#402189">
      <Flex flexWrap="wrap">
        <Box width={[1, 2/3]}>
          <Title>Incredibly Secure.</Title>
          <Description>More secure than a bank, Bidali uses cutting edge technology to prevent fraud, give you more control, and keep you and your money secure.</Description>
          <Tabs activeColor={colors.brand.accent} textColor={colors.typography.invertedSubtle}>
            <TabContent tabTitle="Protected">
              You control your account at all times. Our secure technology ensures no one can access your funds, not even Bidali. In the unlikely event of loss or theft, we&apos;ve still got your back and are able to help you seamlessly restore your account and your funds.
            </TabContent>
            <TabContent tabTitle="Private">
              Most rewards platforms gather tons of information about you without you knowing. We don&apos;t do that and we&apos;re up front about what we collect. With our innovative technology we need less information to deliver relevant offers to you and leave you in the driver&apos;s seat.
            </TabContent>
            <TabContent tabTitle="Compliant">
              Bidali is a regulated financial company based in Canada that is committed to keeping you and your money safe. Our platform has built in fraud protection, is PIPEDA compliant, and boasts better than bank grade security. We work with regulated financial institutions.
              <Button ghost size='small' gaLabel="learn_more_cta" to="/policies/security">Learn More <ArrowRight size={20} /></Button>
            </TabContent>
          </Tabs>
        </Box>
        <Box width={[1, 1/3]} mt={['4em', 0]}>
          <PhoneContainer>
            <Phone isDark>
              <Image height={596} src="/images/screenshots/splash.jpg" alt="Bidali Splash Screen" width={300} />
            </Phone>
            <FaceId>
              <Image height={60} src="/images/screenshots/face-id.gif" alt="Face ID Logo" unoptimized width={60} />
            </FaceId>
          </PhoneContainer>
        </Box>
      </Flex>
    </Section>
  );
};

export default Security;
