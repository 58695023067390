import styled from 'styled-components';
import { Grid } from '@bedrock-layout/grid';
import Image from 'next/image';
import { Flex, Box } from 'reflexbox/styled-components';

import Section from '@/components/section';
import Phone from '@/components/phone';
import TitleBase from '@/components/title';
import SubTitle from '@/components/sub-title';
import AppStoreBadges from '@/components/app-store-badges';
import { colors, media } from '@/themes';

const Container = styled.div`
  video {
    max-width: 100%;
  }
`;

const Title = styled(TitleBase)`
  margin-top: 0;
`;

const RainbowText = styled(SubTitle)`
  font-weight: 600;
  margin: 0 0 1.5em;
  line-height: 1.125;
`;

const HeroContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 4em;

  ${media.md`
    padding: 0 1em;
  `};
`;

const Benefit = styled(SubTitle)`
  font-weight: 100;

  strong {
    font-weight: 600;
  }

  ${media.sm`
    margin-top: 2em;
  `}
`;

const TagLine = styled(SubTitle)`
  color: ${props => props.theme.colors.brand.accent};
`;

const BrandsTitle = styled.h2`
  align-items: center;
  color: ${props => props.theme.colors.typography.subtle};
  display: flex;
  font-size: 1.5em;
  font-weight: 400;
  height: 100%;
  margin: 0;

  ${media.sm`
    align-items: flex-start;
    justify-content: center;
    height: 3em;
  `};
`;

const BrandContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export default function Hero() {
  return (
    <Container>
      <Section backgroundColor={`linear-gradient(to bottom, white, ${colors.background.primary})`} style={{ textAlign: 'center' }}>
        <Title maxSize="5em">Keep More of Your Money</Title>
        <RainbowText maxSize="3em" rainbow vw={7}>Discover a new way to pay and save.</RainbowText>
        <Flex flexWrap="wrap">
          <Box width={[1, 1/3]}>
            <Phone>
              <video loop muted playsInline autoPlay poster="/videos/app-cover.png">
                <source src="/videos/bidali-app.mp4" type="video/mp4"/>
                {/* <source src="/videos/bidali-app.webm" type="video/webm"/> */}
              </video>
            </Phone>
          </Box>
          <Box width={[1, 2/3]}>
            <HeroContent>
              <Benefit maxSize="2em" minSize="1.5em" vw={6}>Earn <strong>instant</strong> cash back on purchases. Send and receive money from anyone for <strong>free</strong>. 🤑</Benefit>
              <TagLine maxSize="2em" minSize="1.5em" vw={6}>Move Money. Make Money.</TagLine>
              <AppStoreBadges />
            </HeroContent>
          </Box>
        </Flex>
        <Flex flexWrap="wrap" mt="6em">
          <Box width={[1, 1/3]}>
            <BrandsTitle>Save with 1,000+ brands.</BrandsTitle>
          </Box>
          <Box width={[1, 2/3]}>
            <Grid gutter="lg" minItemWidth={240}>
              <BrandContainer>
                <Image
                  alt="Amazon logo"
                  height={48}
                  src="/images/brands/amazon-grey.png"
                  width={140} />
              </BrandContainer>
              <BrandContainer>
                <Image
                  alt="PlayStation logo"
                  height={25}
                  src="/images/brands/playstation-grey.png"
                  width={140} />
              </BrandContainer>
              <BrandContainer>
                <Image
                  alt="Starbucks logo"
                  height={35}
                  src="/images/brands/starbucks-grey.png"
                  width={140} />
              </BrandContainer>
              <BrandContainer>
                <Image
                  alt="Banana Republic logo"
                  height={28}
                  src="/images/brands/banana-republic-grey.png"
                  width={140} />
              </BrandContainer>
              <BrandContainer>
                <Image
                  alt="Old Navy logo"
                  height={49}
                  src="/images/brands/old-navy-grey.png"
                  width={140} />
              </BrandContainer>
              <BrandContainer>
                <Image
                  alt="DoorDash logo"
                  height={35}
                  src="/images/brands/doordash-grey.png"
                  width={140} />
              </BrandContainer>
            </Grid>
          </Box>
        </Flex>
      </Section>
    </Container>
  );
}
