import styled from 'styled-components';
import { Flex, Box } from 'reflexbox/styled-components';
import Image from 'next/image';

import { media } from '@/themes';
import Button from '@/components/button';
import config from '@/config';

const AppBadges = styled(Flex)`
  margin-top: 2em;
`;

const Badge = styled(Box)`
  padding: 0 .5em;

  ${media.sm`
    padding: .5em 0;
  `};
`;

const AppStoreBadges = () => {
  return (
    <AppBadges flexWrap="wrap" textAlign={['center', 'inherit']}>
      <Badge width={[1, 1/2]}>
        <Button ghost to={config.appDownloadUrl.production.ios} gaLabel="ios_app_download">
          <Image
            unoptimized
            alt="Download the Bidali app for iOS."
            height={48}
            src="/images/badges/app-store.svg"
            width={160} />
        </Button>
      </Badge>
      <Badge width={[1, 1/2]}>
        <Button ghost to={config.appDownloadUrl.production.android} gaLabel="android_app_download">
          <Image
            unoptimized
            alt="Google Play Store Badge"
            height={48}
            src="/images/badges/play-store.svg"
            width={160} />
        </Button>
      </Badge>
    </AppBadges>
  );
};

export default AppStoreBadges;
