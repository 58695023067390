import styled from 'styled-components';
import { lighten } from 'polished';
import Image from 'next/image';
import { Flex } from 'reflexbox/styled-components';

import { randomInteger } from '@/utils';
import Section from '@/components/section';
import TitleBase from '@/components/title';
import SubTitleBase from '@/components/sub-title';
import NotFullColumn from '@/components/not-full-column';

const backgroundColor = '#6241e2';

const Container = styled.div`
  text-align: center;
`;

const Title = styled(TitleBase)`
  color: ${props => props.theme.colors.typography.inverted};
  margin: 0;
`;

const SubTitle = styled(SubTitleBase)`
  color: ${lighten(.25, backgroundColor)};
  font-weight: 400;
  margin-bottom: 3em;
`;

const Flags = styled.div`
  margin-top: 2em;
  margin-bottom: 1.5em;

  > div + div {
    margin-left: .5em !important;
  }
`;

const People = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Person = styled(Image)`
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  box-shadow: ${props => props.theme.shadows.hover};
  display: inline-block;
`;

const Everyone = () => {
  return (
    <Section angleBottomReverse backgroundColor={backgroundColor}>
      <Container>
        <Flex>
          <NotFullColumn>
            <Title>Built for everyone.</Title>
            <Flags className="flags">
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/CA.png" alt="Canada" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/US.png" alt="United States" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/MX.png" alt="Mexico" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/GB.png" alt="United Kingdom" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/AU.png" alt="Australia" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/IN.png" alt="India" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/IT.png" alt="Italy" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/JP.png" alt="Japan" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/ES.png" alt="Spain" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/BR.png" alt="Brazil" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/AR.png" alt="Argentina" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/NG.png" alt="Nigeria" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/ZA.png" alt="South Africa" />
              <Image className="flag" height={24} width={44} src="https://assets.bidali.com/flags/PH.png" alt="Philippines" />
            </Flags>
            <SubTitle maxSize="1.6em" minSize="1.25em" vw={5}>Bidali is a new digital commerce experience that helps you keep more of your money. Businesses that use Bidali can increase sales, save on fees, get paid faster, and eliminate fraud.</SubTitle>
          </NotFullColumn>
        </Flex>

        <Flex>
          <NotFullColumn>
            <People>
              <div style={{ transform: `translateY(0em)`}}>
                <Person width={64} height={64} src="/images/people/person1.jpg" alt=""/>
              </div>
              <div style={{ transform: `translateY(2em)`}}>
                <Person width={128} height={128} src="/images/people/person2.jpg" alt="" />
              </div>
              <div style={{ transform: `translateY(2em)`}}>
                <Person width={64} height={64} src="/images/people/person3.jpg" alt=""/>
              </div>
              <div style={{ transform: `translateY(0em)`}}>
                <Person width={128} height={128} src="/images/people/person4.jpg" alt=""/>
              </div>
              <div style={{ transform: `translateY(3em)`}}>
                <Person width={192} height={192} src="/images/people/person5.jpg" alt=""/>
              </div>
              <div style={{ transform: `translateY(0em)`}}>
                <Person width={128} height={128} src="/images/people/person6.jpg" alt=""/>
              </div>
              <div style={{ transform: `translateY(1em)`}}>
                <Person width={64} height={64} src="/images/people/person7.jpg" alt=""/>
              </div>
            </People>
          </NotFullColumn>
        </Flex>

        <Flex>
          <NotFullColumn>
            <People>
              <div style={{ transform: `translateY(-4em)`}}>
                <Person width={128} height={128} src="/images/people/person8.jpg" alt="" />
              </div>
              <div style={{ transform: `translateY(0em)`}}>
                <Person width={64} height={64} src="/images/people/person9.jpg" alt="" />
              </div>
              <div style={{ transform: `translateY(-4em)`}}>
                <Person width={192} height={192} src="/images/people/person10.jpg" alt="" />
              </div>
              <div style={{ transform: `translateY(1em)`}}>
                <Person width={128} height={128} src="/images/people/person11.jpg" alt="" />
              </div>
              <div style={{ transform: `translateY(0em)`}}>
                <Person width={288} height={288} src="/images/people/person12.jpg" alt="" />
              </div>
              <div style={{ transform: `translateY(-2em)`}}>
                <Person width={64} height={64} src="/images/people/person13.jpg" alt="" />
              </div>
              <div style={{ transform: `translateY(0em)`}}>
                <Person width={128} height={128} src="/images/people/person14.jpg" alt="" />
              </div>
            </People>
          </NotFullColumn>
        </Flex>
      </Container>
    </Section>
  );
};

export default Everyone;
