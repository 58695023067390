import { withTracker } from '@/tracker';
import Hero from '@/components/home/hero';
import Everyone from '@/components/home/everyone';
import BetterMoney from '@/components/home/better-money';
import HowItWorks from '@/components/home/how-it-works';
import Business from '@/components/home/business';
import Security from '@/components/home/security';
import AppDownloadCTA from '@/components/app-download-cta';

function Home() {
  return (
    <>
      <Hero />
      <Everyone />
      <BetterMoney />
      <HowItWorks />
      <Business />
      <Security />
      <AppDownloadCTA />
    </>
  );
}

export default withTracker(Home);
