import styled from 'styled-components';
import { Flex, Box } from 'reflexbox/styled-components';
import Image from 'next/image';

import { colors, media } from '@/themes';
import Section from '@/components/section';
import Title from '@/components/title';
import SubTitleBase from '@/components/sub-title';
import Paragraph from '@/components/paragraph';

const Content = styled.div`
  text-align: center;
`;

const SubTitle = styled(SubTitleBase)`
  color: ${props => props.theme.colors.typography.subtle};
  margin-bottom: 2em;
`;

const Step = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: 20%;

  ${media.md`
    align-items: flex-start;
    margin-left: 5%;
  `};

  ${media.sm`
    display: block;
    margin-left: 0;
  `};
`;

const Illustration = styled(Image)`
  max-width: 100%;
  transform: scale(.85);
`;

const StepCount = styled.h2`
  align-items: center;
  background: ${props => props.theme.colors.background.card};
  border-radius: 50%;
  box-shadow: ${props => props.theme.shadows.primary};
  color: ${props => props.theme.colors.brand.primary};
  display: flex;
  flex-shrink: 0;
  font-size: 3em;
  height: 2em;
  justify-content: center;
  line-height: 1;
  margin: 0;
  width: 2em;

  ${media.sm`
    float: left;
    font-size: 2.5em;
    margin-right: .5em;
  `};
`;

const StepContent = styled.div`
  padding-left: 2em;

  ${media.sm`
    margin-top: 1em;
    padding-left: 0;
  `};
`;

const StepTitle = styled.h3`
  font-size: 2em;
  font-weight: 400;
  margin-top: 0;
`;

const HowItWorks = () => {
  return (
    <Section angleBottomReverse backgroundColor={colors.background.primary}>
      <Content>
        <Title>How It Works</Title>
        <SubTitle>Download the app, connect your bank account, and start saving!</SubTitle>
      </Content>
      <Flex flexWrap="wrap">
        <Box width={[1, 1/3]}>
          <Illustration width={480} height={372} src="/images/illustrations/connect-bank.png" />
        </Box>
        <Box width={[1, 2/3]}>
          <Step>
            <StepCount>1</StepCount>
            <StepContent>
              <StepTitle>Connect your bank account</StepTitle>
              <Paragraph>Download the Bidali app, create your account, and top up your balance in just a few taps. We encrypt your data right on your device so only you can access your funds and you are in complete control.</Paragraph>
            </StepContent>
          </Step>
        </Box>

        <Box width={[1, 1/3]}>
          <Illustration width={480} height={372} src="/images/illustrations/giftcard-cashback.png" />
        </Box>
        <Box width={[1, 2/3]}>
          <Step>
            <StepCount>2</StepCount>
            <StepContent>
              <StepTitle>Buy gift cards and get cash back</StepTitle>
              <Paragraph>Top up your Bidali Account balance and buy gift cards from thousands of local and international brands. After a successful purchase you&apos;ll instantly earn Bidali Cash <sup>†</sup> back.</Paragraph>
            </StepContent>
          </Step>
        </Box>

        <Box width={[1, 1/3]}>
          <Illustration width={480} height={372} src="/images/illustrations/shop.png" />
        </Box>
        <Box width={[1, 2/3]}>
          <Step>
            <StepCount>3</StepCount>
            <StepContent>
              <StepTitle>Pay in store or online</StepTitle>
              <Paragraph>Use your digital gift cards to pay online or in store, or send them to a friend. You can always top up with additional gift cards or other payment methods. Such a great way to budget and save!</Paragraph>
            </StepContent>
          </Step>
        </Box>

        <Box width={[1, 1/3]}>
          <Illustration width={480} height={372} src="/images/illustrations/send-money.png" />
        </Box>
        <Box width={[1, 2/3]}>
          <Step>
            <StepCount>4</StepCount>
            <StepContent>
              <StepTitle>Use your earned cash</StepTitle>
              <Paragraph>Stretch your money further and use your earned Bidali Cash to buy more gift cards, send to a friend, or pay in store and online where Bidali is accepted. All for <strong>free</strong>. No catches or hidden fees.</Paragraph>
            </StepContent>
          </Step>
        </Box>

        <Box width={[1, 1/3]}>
          <Illustration width={480} height={372} src="/images/illustrations/offers.png" />
        </Box>
        <Box width={[1, 2/3]}>
          <Step>
            <StepCount>5</StepCount>
            <StepContent>
              <StepTitle>Collect exclusive offers</StepTitle>
              <Paragraph>Stack your savings by getting access to exclusive offers from local and international brands. Businesses are able to immediately send you credit that you can redeem alongside your gift cards and Bidali Cash. Win, win, win!</Paragraph>
            </StepContent>
          </Step>
        </Box>
      </Flex>
    </Section>
  );
};

export default HowItWorks;
